<template>
    <section class="h-100">
        <div v-if="id_pedido" class="row mx-0 p-2 px-3 border-bottom align-items-center">
            <div class="btn-action mr-2 d-middle-center border text-general br-8" @click="imprimir">
                <i class="icon-printer f-20" />
            </div>
            <template v-if="pedido.estado==1 && pedido.directo == 0">
                <p><b>ID. {{ pedido.id }} </b>Pedido sin confirmar</p>
                <button class="btn br-10 border bg-whitesmoke ml-auto mx-2" type="button" @click="rechazarPedido">
                    Rechazar
                </button>
                <div type="button" class="br-10 bg-general text-white px-2 py-1" @click="confirmarPedido">
                    Confirmar
                </div>
            </template>
            <template v-else-if="[2,201,202,11,110,12,13,3,31].indexOf(pedido.estado) > -1 && pedido.directo == 0">
                <div class="col-xl-auto col-lg-auto col-md-12 col-sm-12 col-12 px-0 text-general">
                    <p v-show="pedido.estado === 2"><b>ID. {{ pedido.id }} </b>Pedido Confirmado</p>
                    <p v-show="pedido.estado === 201"><b>ID. {{ pedido.id }} </b>Pedido En Alistamiento</p>
                    <p v-show="pedido.estado === 202"><b>ID. {{ pedido.id }} </b>Pedido En Empacado</p>
                    <p v-show="pedido.estado === 11"><b>ID. {{ pedido.id }} </b>Pedido Editado (Esperando confirmación del cliente...)</p>
                    <p v-show="pedido.estado === 12"><b>ID. {{ pedido.id }} </b>Pedido Confirmado Por Cliente</p>
                    <p v-show="pedido.estado === 13"><b>ID. {{ pedido.id }} </b>Pedido Con Cambios Del Cliente</p>
                    <p v-show="[3,31].indexOf(pedido.estado) > -1"><b>ID. {{ pedido.id }} </b>Pedido Enviado</p>
                </div>

                <div v-show="pedido.estado === 110 && pedido.manual === 0" class="d-middle">
                    <i class="icon-left f-18 text-gr-red cr-pointer" @click="modal_descartar_cambios" />
                    <b>Editar pedido ID. {{ pedido.id }}</b>
                </div>

                <div v-if="pedido.manual === 1" type="button" class="br-10 bg-general text-white p-2 ml-auto" @click="$refs.modalAgregarProducto.toggle()">
                    <el-tooltip class="item" effect="light" content="Añadir producto" placement="bottom">
                        <i class="icon-plus f-20 cr-pointer text-white" />
                    </el-tooltip>
                </div>

                <div class="d-middle ml-auto">
                    <div v-show="pedido.estado === 31" type="button" class="br-10 border-orange bg-white text-gr-red p-2 d-middle" @click="verChat">
                        <i class="icon-attention text-gr-red mr-2" />
                        Reclamo Reportado
                        <i class="icon-angle-right text-gr-red" />
                    </div>
                    <el-tooltip v-if="pedido.manual === 1" class="item" effect="light" content="Descartar Pedido" placement="bottom">
                        <i class="icon-trash f-22 cr-pointer text-danger mx-2" @click="$refs.modalEliminarPedido.toggle()" />
                    </el-tooltip>
                    <el-tooltip v-show="[2,201,202,3,4,110,11,12,13].indexOf(pedido.estado) > -1 && $tienda.chat.clientes===1 && pedido.manual === 0" class="item" effect="light" content="Hablar con el cliente" placement="bottom">
                        <el-badge :value="pedido.cant_mensajes" class="item mx-2 mr-4">
                            <i class="icon-message-text-outline cr-pointer f-22 text-gr-general" @click="verChat" />
                        </el-badge>
                    </el-tooltip>
                    <div v-show="[2,12,13].indexOf(pedido.estado) > -1" type="button" class="br-10 bg-general text-white px-2 py-1" @click="cambiar_estado(201)">
                        Alistar Pedido
                    </div>
                    <div v-show="[201].indexOf(pedido.estado) > -1" type="button" class="br-10 bg-general text-white px-2 py-1" @click="cambiar_estado(202)">
                        Empacar Pedido
                    </div>
                    <div v-show="[202].indexOf(pedido.estado) > -1" type="button" class="br-10 bg-general text-white px-2 py-1" @click="$refs.modalEnviarPedido.toggle()">
                        Enviar Pedido
                    </div>
                    <div v-show="[3].indexOf(pedido.estado) > -1" type="button" class="br-10 bg-general text-white px-2 py-1" @click="$refs.modalFinalizarPedido.toggle()">
                        Finalizar Pedido
                    </div>
                    <div v-show="pedido.estado === 110 && pedido.manual === 0" type="button" class="br-10 bg-general text-white px-2 py-1" @click="modal_enviar_cambios">
                        Enviar cambios del pedido
                    </div>

                    <el-dropdown class="ml-2" trigger="click" @command="accionBoton">
                        <i class="icon-dots-horizontal br-10 f-25 _df_book-cover" />
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-show="[2,12,13,201,202].indexOf(pedido.estado) > -1 && pedido.manual === 0" :command="3">
                                <i class="icon-playlist-edit cr-pointer f-24" />
                                <span>Editar Lista de Productos</span>
                            </el-dropdown-item>
                            <el-dropdown-item :command="1">
                                <el-tooltip placement="left" effect="light">
                                    <div slot="content">
                                        Modificar las sugerencias, datos de fecha<br />y hora de entrega del pedido.
                                    </div>
                                    <i class="icon-calendar-clock f-20" />
                                </el-tooltip>
                                <span>Editar Datos Pedido</span>
                            </el-dropdown-item>
                            <el-dropdown-item v-show="[2,201,202,3].indexOf(pedido.estado) > -1 && ($tienda.cliente_pedido_directo == 1 || $tienda.cambio_tipo_pedido == 1)" :command="6">
                                <el-tooltip placement="left" effect="light">
                                    <div slot="content">
                                        Trasladar la responsabilidad y gestión del pedido<br />al Centro de distribución, como pedido directo.
                                    </div>
                                    <i class="icon-receipt f-20" />
                                </el-tooltip>
                                <span>Trasladar gestión del Pedido al Cedis</span>
                            </el-dropdown-item>
                            <el-dropdown-item :command="4">
                                <el-tooltip placement="left" effect="light">
                                    <div slot="content">
                                        Agregar los productos de este pedido<br />a mi carrito de compras de abastecimiento.
                                    </div>
                                    <i class="icon-cart-plus f-20" />
                                </el-tooltip>
                                <span>Añadir al carrito de abastecimiento</span>
                            </el-dropdown-item>
                            <el-dropdown-item :command="5">
                                <el-tooltip placement="left" effect="light">
                                    <div slot="content">
                                        Agregar los productos de este pedido<br />a un nuevo pedido de abastecimiento.
                                    </div>
                                    <i class="icon-receipt f-20" />
                                </el-tooltip>
                                <span>Convertir en Pedido de abastecimiento</span>
                            </el-dropdown-item>
                            <el-dropdown-item v-if="pedido.manual === 0" :command="2">
                                <i class="icon-cancel-circled-outline f-20" />
                                <span>Cancelar Pedido</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </template>
            <template v-else-if="pedido.directo == 1">
                <div class="col tres-puntos px-0 text-general">
                    <p v-show="pedido.estado === 1" class="tres-puntos"><b>ID. {{ pedido.id }} </b>Pedido Sin Confirmar</p>
                    <p v-show="pedido.estado === 2" class="tres-puntos"><b>ID. {{ pedido.id }} </b>Pedido Confirmado</p>
                    <p v-show="pedido.estado === 110" class="tres-puntos"><b>ID. {{ pedido.id }} </b>Pedido En Edición</p>
                    <p v-show="pedido.estado === 201" class="tres-puntos"><b>ID. {{ pedido.id }} </b>Pedido En Alistamiento</p>
                    <p v-show="pedido.estado === 202" class="tres-puntos"><b>ID. {{ pedido.id }} </b>Pedido En Empacado</p>
                    <p v-show="pedido.estado === 11" class="tres-puntos"><b>ID. {{ pedido.id }} </b>Pedido Editado (Esperando confirmación del cliente...)</p>
                    <p v-show="pedido.estado === 12" class="tres-puntos"><b>ID. {{ pedido.id }} </b>Pedido Confirmado Por Cliente</p>
                    <p v-show="pedido.estado === 13" class="tres-puntos"><b>ID. {{ pedido.id }} </b>Pedido Con Cambios Del Cliente</p>
                    <p v-show="[3,31].indexOf(pedido.estado) > -1"><b>ID. {{ pedido.id }} </b>Pedido Enviado</p>
                </div>

                <div class="d-middle">
                    <el-tooltip class="item" effect="light" content="Este pedido está siendo administrado por el Cedis" placement="bottom">
                        <i class="icon-warehouse f-22 cr-pointer text-gr-general mx-2" />
                    </el-tooltip>
                    <el-tooltip v-show="[2,201,202,3,4,110,11,12,13].indexOf(pedido.estado) > -1 && $tienda.chat.clientes===1 && pedido.manual === 0" class="item" effect="light" content="Hablar con el cliente" placement="bottom">
                        <el-badge :value="pedido.cant_mensajes" class="item mx-2 mr-4">
                            <i class="icon-message-text cr-pointer f-22 text-gr-general" @click="verChat" />
                        </el-badge>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" content="Editar datos del Pedido" placement="bottom">
                        <i class="icon-calendar-clock f-22 cr-pointer text-gr-general mx-2" @click="editar_datos_pedido" />
                    </el-tooltip>
                    <div v-show="[1,2,201,202].indexOf(pedido.estado_admin) > -1" type="button" class="br-10 bg-general text-white px-2 py-1 mr-2" @click="cancelarPedido">
                        Cancelar
                    </div>
                    <div v-show="[3,4,31,32].indexOf(pedido.estado_admin) > -1" type="button" class="br-10 bg-general text-white px-2 py-1 mr-2" @click="$refs.modalAdvertencia.toggle()">
                        Cancelar
                    </div>
                    <div v-show="[3,4,31,32].indexOf(pedido.estado_admin) > -1 && pedido.estado == 202" type="button" class="br-10 bg-general text-white px-2 py-1" @click="$refs.modalEnviarPedido.toggle()">
                        Enviar Pedido
                    </div>
                    <div v-show="pedido.estado == 3" type="button" class="br-10 bg-general text-white px-2 py-1" @click="$refs.modalFinalizarPedido.toggle()">
                        Finalizar Pedido
                    </div>
                </div>
            </template>
        </div>
        <div class="row mx-0 container-cols" style="height:calc(100% - 58px);">
            <div class="col-xl-6 col-lg-5 col-md col-sm col px-0 h-100 border-right">
                <div v-show="id_pedido !== null" class="mx-5 my-2">
                    <el-input v-model="buscar" size="medium" clearable placeholder="Buscar producto" class="br-20" />
                </div>
                <div ref="cont1" class="p-3 f-15 overflow-auto custom-scroll" style="height:calc(100% - 55px);">
                    <div class="row mx-0 justify-center">
                        <card-producto-pedido
                        v-for="(prod, idx) in productos.filter(data => !buscar || data.producto.nombre.toLowerCase().includes(buscar.toLowerCase()))" :key="`prod-${idx}`"
                        :info="prod"
                        :diseno="prod.data_promocion"
                        class="cr-pointer"
                        @accion="modalProducto"
                        @icono="cambiar_icono"
                        />
                    </div>

                    <contentLoader v-show="loading" />

                    <div v-show="id_pedido === null" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="80" src="/img/no-imagen/click.svg" alt="" />
                            </div>
                            <p>Selecciona uno de los pedidos activos del panel de la izquierda.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl col-lg-5 col-md col-sm col px-0 h-100 overflow-auto custom-scroll">
                <div class="row mx-0 border-bottom">
                    <div
                    class="col py-2 pt-0 d-middle-center cr-pointer"
                    :class="tab=='detalle'? 'border-b-red text-general-red':''"
                    @click="tab='detalle'"
                    >
                        Detalle
                    </div>
                    <div
                    v-if="!_.isEmpty(pedido) && pedido.pago_estado != 1"
                    class="col py-2 pt-0 d-middle-center cr-pointer"
                    :class="tab=='pago'? 'border-b-red text-general-red':''"
                    @click="tab='pago'"
                    >
                        Pagos
                    </div>
                </div>
                <detalle v-show="tab == 'detalle' && id_pedido" ref="detallePedido" :pedido="pedido" />
                <pago v-show="tab === 'pago'" :pedido="pedido" :vista="2" @cancelar="tab = 'detalle'" />
            </div>
        </div>
        <modalConfirmarPedido ref="modalConfirmarPedido" />
        <modalDatosPedido ref="modalDatosPedido" />
        <modalAgregarProducto ref="modalAgregarProducto" @update="update_lista" />


        <modalDetalleProd ref="modalDetalleProd" />
        <modalProductoDesconocido ref="modalProductoDesconocido" />
        <modalRechazarPedido ref="modalRechazarPedido" />
        <modalCancelarPedido ref="modalCancelarPedido" />
        <modalChat ref="modalChat" :pedido="pedido" :leechero="cliente" @actualizarIcono="actualizarIconoChat" />
        <modalStockBajo ref="modalStockBajo" />
        <modalRechazarCambios ref="modalRechazarCambios" @descartar="descartar_cambios" />
        <modalEditarProd ref="modalEditarProd" @eliminar="modalEliminar" />
        <modalEditarProdCambiado ref="modalEditarProdCambiado" />
        <modalProductoManual ref="modalProductoManual" />
        <modalFinalizarPedido ref="modalFinalizarPedido" @credito="modalCredito(true,2)" />

        <modalProductoPrecioSugerido ref="modalProductoPrecioSugerido" />

        <modalConfirmar
        ref="modalEnviarCambios"
        titulo="Enviar cambios"
        mensaje="¿Desea enviarle los cambios del pedido al cliente?" icon="enviar-pedido"
        @guardar="confirmar_cambios"
        />

        <modalConfirmar
        ref="modalEliminarProd"
        titulo="Eliminar producto"
        mensaje="¿Estás seguro de eliminar el producto del pedido?" icon="shopping-cart"
        @guardar="eliminar_producto"
        />

        <modalConfirmar
        ref="modalEliminarPedido"
        titulo="Eliminar Pedido"
        mensaje="¿Estás seguro de eliminar el pedido?" icon="shopping-cart"
        @guardar="descartar_pedido"
        />


        <modalConfirmar
        ref="modalRevertirEliminado"
        titulo="Recuperar Producto"
        mensaje="¿Desea recuperar el producto?" icon="shopping-cart"
        @guardar="recuperar_producto"
        />


        <modal ref="modalEnviarPedido" titulo="Enviar Pedido" icon="bicycle-l" @guardar="confirmar_envio">
            <div class="row mx-0 justify-center">
                <div class="col-10 text-center f-14">
                    ¿Este pedido se enviara a una direccion diferente a la actual del cliente?
                </div>
            </div>
            <div class="row mx-0 my-3 justify-center">
                <div class="col-10 text-center">
                    <el-radio v-model="pedido.otra_direccion" :label="1">
                        Si
                    </el-radio>
                    <el-radio v-model="pedido.otra_direccion" :label="0">
                        No
                    </el-radio>
                </div>
            </div>
            <div class="row mx-0 justify-center">
                <div class="col-10 text-center">
                    ¿Desea Enviar el pedido?
                </div>
            </div>
        </modal>

        <modalConfirmar
        ref="modalCambioEstadoPedido"
        :titulo="titulo_estado"
        :mensaje="mensaje_estado"
        @guardar="cambiar_estado_pedido"
        />

        <modalSiNo
        ref="modalOfrecerCredito"
        titulo="Otorgar Credito"
        mensaje="¿Desea otorgar credito al cliente?"
        icon="money"
        @accion="modalCredito"
        />

        <modalSiNo
        ref="trasladarACedis"
        titulo="Trasladar Pedido"
        mensaje="¿Desea trasladar la gestion del pedido al Centro de Distribución?"
        icon="Alistar"
        @accion="transladarPedidoACedis"
        />

        <modal ref="modalAdvertencia" titulo="¡Aguarda!" icon="close" center-title cerrar no-aceptar no-cancelar>
            <div class="row mx-0 justify-center">
                <div class="col-12 text-general text-center f-15 mt-3">
                    <p class="f-500 f-16 mb-3">¡Este pedido ya esta en la ruta de entrega!</p>
                    Para poder cancelar por favor comunicate con el Centro de Distribución.
                </div>
            </div>
        </modal>

        <modalCarritoAlmacen ref="modalCarrito" :carrito="Escarrito" :pedido="EsPedido" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-tienda'
import PdfPedidos from '~/services/pdf/pdf'

export default {
    components: {
        detalle: () => import('./detalle'),
        pago: () => import('~/pages/almacen/tendero/historial/pago.vue'),

        modalConfirmarPedido: () => import('./partials/modalConfirmarPedido'),
        modalDetalleProd: () => import('./partials/modalDetalleProducto'),
        modalProductoDesconocido: () => import('./partials/modalProductoDesconocido'),
        modalRechazarPedido: () => import('./partials/modalRechazarPedido'),
        modalCancelarPedido: () => import('./partials/modalCancelarPedido'),
        modalCarritoAlmacen: () => import('./partials/modalCarritoAbast'),

        modalStockBajo: () => import('./partials/modalStockBajo'),

        modalRechazarCambios: () => import('./partials/modalRechazarCambios'),
        modalEditarProd: () => import('./partials/modalEditarProd'),
        modalEditarProdCambiado: () => import('./partials/modalEditarProdCambiado'),
        cardProductoPedido: () => import('./partials/card-producto-pedido'),
        modalDatosPedido: () => import('./partials/modalEditarDatosPedido'),
        modalAgregarProducto: () => import('./partials/modalAgregarProductos'),
        modalProductoManual: () => import('./partials/modalProductoManual'),
        modalFinalizarPedido: () => import('./partials/modalFinalizarPedido'),
        modalProductoPrecioSugerido: () => import('./partials/modalProductoPrecioSugerido'),
    },
    data(){
        return {
            estado:null,
            titulo_estado:'',
            mensaje_estado:'',
            editar: false,
            loading:false,
            id_eliminar:null,
            Escarrito:false,
            EsPedido:false,
            buscar:'',
            tab: 'detalle'
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos/id_pedido',
            productos: 'pedidos/pedidos/productos',
            cliente: 'pedidos/pedidos/cliente',
            pedido: 'pedidos/pedidos/pedido',
        }),
        rol(){
            return this.$usuario.rol_nombre
        },
    },
    watch:{
        id_pedido(val){
            this.tab = 'detalle'
            if(val){
                this.listar_productos()
                this.buscar = ''
            }else{
                this.$store.commit('pedidos/pedidos/set_id_pedido', null)
                this.$store.commit('pedidos/pedidos/set_pedido', {})
                this.$store.commit('pedidos/pedidos/set_productos', [])
            }
        }
    },
    methods: {
        async listar_productos(){
            try {
                this.loading = true
                await this.$store.dispatch('pedidos/pedidos/pedidos_productos',this.id_pedido)

            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        },
        async cambiar_icono(obj){
            try {
                const {data} = await Pedidos.cambiar_icono(obj)
            } catch (e){
                this.error_catch(e)
            }
        },
        update_lista(){
            this.$refs.cont1.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        confirmarPedido(){
            this.$refs.modalConfirmarPedido.toggle()
        },
        rechazarPedido(){
            this.$refs.modalRechazarPedido.toggle()
        },
        cancelarPedido(){
            this.$refs.modalCancelarPedido.toggle()
        },
        editar_datos_pedido(){
            this.$refs.modalDatosPedido.toggle()
        },
        cambiar_estado(state){
            if(state === 201){
                this.titulo_estado = 'Alistar Pedido'
                this.mensaje_estado = '¿Desea Alistar el Pedido?'
                this.estado = state
            }else if(state === 202){
                this.titulo_estado = 'Empacar Pedido'
                this.mensaje_estado = '¿Desea Empacar el Pedido?'
                this.estado = state
            }else{
                return
            }
            this.$refs.modalCambioEstadoPedido.toggle()
        },
        async cambiar_estado_pedido(){
            try {
                let model = {
                    estado:this.estado
                }
                if(this.id_pedido === null || this.id_pedido === undefined){
                    this.notificacion('Alerta','Por favor selecciona un pedido','warning')
                    return
                }
                const {data} = await Pedidos.alistar_empacar(this.id_pedido,model)
                if(data.exito){
                    this.$store.commit('pedidos/pedidos/cambio_estado_pedido',{id:this.id_pedido,estado:this.estado})
                    this.$store.dispatch('pedidos/pedidos/pedidos_historial',this.id_pedido)
                    this.pedido.estado = this.estado
                    this.$refs.modalCambioEstadoPedido.toggle()
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        verChat(){
            let momento = 1
            if(this.pedido.estado !== 31){
                momento = 1;
            }else{
                momento = 2;
            }
            let id = this.pedido.directo == 1 ? this.pedido.id_admin_pedido : this.id_pedido
            let tipo = this.pedido.directo == 1 ? 8: 2
            this.$refs.modalChat.toggle(this.id_pedido, 2, false, momento) // 2 = tipo pedido
        },
        async editarPedido(){
            try {
                const {data} = await Pedidos.editar_tendero(this.id_pedido)
                this.notificacion('Mensaje','Pedido en edicion','success')
                this.$store.commit('pedidos/pedidos/pasar_edicion_tendero',this.id_pedido)
                this.$store.commit('pedidos/pedidos/pedidos_historial',this.id_pedido)
            } catch (e){
                this.error_catch(e)
            }
        },

        modalEliminar(id_pedido_producto){
            this.id_eliminar = id_pedido_producto
            this.$refs.modalEliminarProd.toggle()
        },
        async eliminar_producto(){
            try {
                const {data} = await Pedidos.eliminar_recuperar_producto(this.id_eliminar)

                this.notificacion('Mensaje','Producto paso a estado eliminado','success')
                this.$refs.modalEliminarProd.toggle()
                //recalculamos todos los datos
                this.recalcular_pedido()

            } catch (e){
                this.error_catch(e)
            }
        },
        async recuperar_producto(){
            try {
                const {data} = await Pedidos.eliminar_recuperar_producto(this.id_eliminar)

                this.notificacion('Mensaje','Producto recuperado correctamente','success')
                this.$refs.modalRevertirEliminado.toggle()
                //recalculamos todos los datos
                this.recalcular_pedido()


            } catch (e){
                this.error_catch(e)
            }
        },
        modal_enviar_cambios(){
            this.$refs.modalEnviarCambios.toggle()
        },
        async confirmar_cambios(){
            try {
                const {data} = await Pedidos.confirmar_cambios_pedido(this.id_pedido)
                this.notificacion('Cambios Enviados','Esperando confirmación del cliente','success')
                this.$refs.modalEnviarCambios.toggle()
                this.$store.commit('pedidos/pedidos/cambio_estado_pedido',{id:this.id_pedido,estado:11})
                this.pedido.estado = 11
                // this.recalcular_pedido()
                this.$store.dispatch('pedidos/pedidos/pedidos_historial',this.id_pedido)
            } catch (e){
                this.error_catch(e)
            }
        },
        modal_descartar_cambios(){
            this.$refs.modalRechazarCambios.toggle()
        },
        async descartar_cambios(){
            try {
                const {data} = await Pedidos.descartar_cambios_pedido(this.id_pedido)
                this.notificacion('Cambios Descartados','Se han descartado todos los cambios en el pedido','success')
                this.$refs.modalRechazarCambios.toggle()
                // this.recalcular_pedido()
                this.$store.commit('pedidos/pedidos/cambio_estado_pedido',{id:this.id_pedido,estado:2})
                this.pedido.estado = 2
                this.$store.dispatch('pedidos/pedidos/pedidos_historial',this.id_pedido)
                this.$store.dispatch('pedidos/pedidos/pedidos_productos', Number(this.id_pedido))
            } catch (e){
                this.error_catch(e)
            }
        },

        recalcular_pedido(){
            this.$store.dispatch('pedidos/pedidos/listar_pedidos')
            this.$store.dispatch('pedidos/pedidos/pedidos_productos', Number(this.id_pedido))
            this.$store.dispatch('pedidos/pedidos/pedidos_resumenes', Number(this.id_pedido))
        },

        async confirmar_envio(){
            try {

                if(this.id_pedido == null || this.id_pedido == undefined){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }
                if(this.productos.length === 0){
                    this.notificacion('Alerta','Debe agregar almenos un (1) producto','warning')
                    return
                }

                let value_direccion = this.pedido.otra_direccion

                const {data} = await Pedidos.confirmar_envio_pedido(this.id_pedido,value_direccion)

                if(data.exito){
                    this.$refs.modalEnviarPedido.toggle()
                    this.$store.commit('pedidos/pedidos/cambio_estado_pedido',{id:this.id_pedido,estado:3})
                    this.$store.dispatch('pedidos/pedidos/pedidos_historial',this.id_pedido)
                    this.pedido.estado = 3
                    this.notificacion('Pedido Enviado','','success')

                    if(this.pedido.pago_estado == 1){
                        this.$refs.modalOfrecerCredito.toggle()
                    }
                    return
                }

                if(data.agotados > 0){
                    this.$refs.modalEnviarPedido.toggle()
                    this.$store.commit('pedidos/pedidos/set_productos_stock_bajo', data.productos)
                    this.$refs.modalStockBajo.toggle()
                    return
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        modalCredito(res,opcion=1){
            if(opcion === 1){
                this.$refs.modalOfrecerCredito.toggle()
                if(res){
                    this.$refs.detallePedido.otorgarCredito()
                }
            }else if(opcion === 2){
                this.$refs.detallePedido.otorgarCredito()
            }
        },


        modalProducto(prod){

            if(this.pedido.directo){
                if(this.$tienda.permiso_descuento){
                    this.$refs.modalProductoPrecioSugerido.toggle(prod)
                    return
                }
            }

            if(this.pedido.manual === 1){
                this.$refs.modalProductoManual.datos_basicos(prod)
                return;
            }

            if(this.pedido.estado === 110 && this.pedido.directo == 0){

                if(prod.estado === 11 || prod.estado === 12 ){
                    this.$refs.modalEditarProd.datos_basicos(prod) // modal para cambia, agregar, eliminar producto
                }
                else if(prod.estado === 3){
                    this.$refs.modalProductoDesconocido.toggle(prod)  // modal para cambiar un producto desconocido por uno real
                }
                else if (prod.estado === 21 || prod.estado === 22 || prod.estado === 23 || prod.estado === 4){

                    this.$refs.modalEditarProdCambiado.datos_cambio(prod)  // modal de cambio ver inicial y final
                }
                else if(prod.estado === 51){
                    this.id_eliminar = prod.id
                    this.$refs.modalRevertirEliminado.toggle()  // modal para recuperar un producto que fue eliminado
                }

            }else{
                if(prod.estado === 3){
                    this.$refs.modalProductoDesconocido.detalle_desconocido(prod)  // modal detalle desconocido
                    return
                }
                this.$refs.modalDetalleProd.datos_cambio(prod) // modal detalle producto
            }

        },
        async descartar_pedido(){
            try {
                const {data} = await Pedidos.descartar_pedido_manual(this.id_pedido)
                this.$store.commit('pedidos/pedidos/set_id_pedido', null)
                this.$router.push({name:'tendero.pedidos'})
                this.$refs.modalEliminarPedido.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        actualizarIconoChat(){
            this.$store.commit('pedidos/pedidos/set_pedido', {...this.pedido, cant_mensajes: 0})
            // para actualizar el listado de pedidos
            // let idx_confirmado = this.confirmados.findIndex(e => e.id === this.id_pedido)
            // let idx_enviado= this.enviados.findIndex(e => e.id === this.id_pedido)
            // if(idx_confirmado >= 0){
            //     let found = this.confirmados[idx_confirmado]
            //     found.cant_mensajes = 0
            // }else if(idx_enviado >= 0){
            //     let found = this.enviados[idx_enviado]
            //     found.cant_mensajes = 0
            // }
        },
        async imprimir(){
            try {
                const {data} = await PdfPedidos.generar_pdf_ticket_pedidos_cliente({id_pedido: this.id_pedido})
                const url = window.URL.createObjectURL(new Blob([data], {type:"application/pdf"}));
                const pdfWindow = window.open(url);
                pdfWindow.print();


            } catch (e){
                this.error_catch(e)
            }
        },
        accionBoton(key){
            switch (key){
            case 1:
                this.editar_datos_pedido()
                break;
            case 2:
                this.cancelarPedido()
                break;
            case 3:
                this.editarPedido()
                break;
            case 4:
                this.agregarAlCarritoDeAlmacen()
                break;
            case 5:
                this.agregarAPedidos()
                break;
            case 6:
                this.$refs.trasladarACedis.toggle()
                break;

            default:
                break;
            }
        },
        agregarAlCarritoDeAlmacen(){
            this.Escarrito = true
            this.EsPedido = false
            this.$refs.modalCarrito.toggle()
        },
        agregarAPedidos(){
            this.Escarrito = false
            this.EsPedido = true
            this.$refs.modalCarrito.toggle()
        },
        async transladarPedidoACedis(res){
            if(!res){
                this.$refs.trasladarACedis.toggle()
                return
            }
            if(this.id_pedido == null || this.id_pedido == undefined){
                this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                return
            }
            if(this.productos.length === 0){
                this.notificacion('Alerta','Debe agregar almenos un (1) producto','warning')
                return
            }

            try {
                const {data} = await Pedidos.pasarAdirecto(this.id_pedido) // Pasar el pedido para que sea administrado por el Cedis
                this.$store.dispatch('pedidos/pedidos/get_datos', Number(this.id_pedido))
                this.$refs.trasladarACedis.toggle()
                this.notificacion('Mensaje','El Pedido ha pasado a ser administrado por el Cedis','success')
            } catch (e){
                this.error_catch(e)
            }

        }

    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.bg-gr-prod{
    background: linear-gradient( to right, #03D6BC20, #0D4DFF20)
}

@media(max-width:980px){
    .container-cols{
        height: calc(100vh - 173px) !important;
    }
}
</style>
